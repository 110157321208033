<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入订单号/手机号/姓名" style="width: 260px;" clearable/>
      <el-input v-model="listQuery.min_money" placeholder="最小金额" style="width: 120px;" clearable/>
      -
      <el-input v-model="listQuery.max_money" placeholder="最大金额" style="width: 120px;" clearable/>
      <el-date-picker v-model="dateArr" type="daterange" align="right" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-button type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>
    <div class="total">
      <div class="total-num">
        <span>{{total_pay_count}}</span>
        <span>销售数量合计</span>
      </div>
      <div class="total-price">
        <span>{{total_pay_sum}}</span>
        <span>销售金额合计(元)</span>
      </div>
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column align="center" label="ID" width="80">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="订单号" min-width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_no }}
        </template>
      </el-table-column>
      <el-table-column label="微信" min-width="110" align="center">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction:column;align-items:center;text-align:center;">
            <img :src="scope.row.user.avatar" class="user-avatar">
            <span>{{ scope.row.user.nickname }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="用户" min-width="110" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.user.name }}</div>
          <div>{{ scope.row.user.phone }}</div>
        </template>
      </el-table-column>
      <el-table-column label="充值金额" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.pay_money }}
        </template>
      </el-table-column>
      <el-table-column label="支付状态" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.pay_status == 0">未支付</span>
          <span v-else-if="scope.row.pay_status == 1">已支付</span>
          <span v-else-if="scope.row.pay_status == 2">已退款</span>
        </template>
      </el-table-column>
      <el-table-column label="支付时间" min-width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.pay_time }}
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      memberLevels: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: '',
        keyword: '',
        start_time: '',
        end_time: '',
        min_money: "",
        max_money: "",
      },
      dateArr: null,
      total_pay_count: 0,
      total_pay_sum: 0,
    };
  },
  created() {
    this.getList();
    this.getMemberLevels();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
    },
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_time = newVal[0]
        this.listQuery.end_time = newVal[1]
      } else {
        this.listQuery.start_time = ''
        this.listQuery.end_time = ''
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/wallet/chargeRecord",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.total_pay_count = response.data.total_pay_count;
        this.total_pay_sum = response.data.total_pay_sum;
        this.listLoading = false;
      });
    },
    getMemberLevels() {
      this.listLoading = true;
      request({
        url: "/api/backend/memberLevel/list",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          type: 2
        },
      }).then(response => {
        this.memberLevels = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.custom-inline-form {
  margin-bottom: 10px;
  .el-row {
    padding: 4px 0;
    font-weight: bold;
  }
  .el-form-item {
    margin-bottom: 4px !important;
  }
  .el-form-item__label {
    line-height: 20px !important;
  }
  .el-form-item__content {
    line-height: 20px !important;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.total {
  width: 100%;
  height: 120px;
  border: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 10px auto;
  .total-num,
  .total-price {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    span {
      margin-bottom: 10px;
    }
  }
  .total-num {
    border-right: 1px solid #f0f0f0;
  }
}
</style>
